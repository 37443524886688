// import featured_image from '../assets/images/braingym.png'
let me;
export default me = {
    "public_id": "74391d4a-38ab-4b5d-a5d0-cd513d9d3f6c",
    "template": "generic-download-2",
    "campaign": "1cd0f14a-bfbe-4336-a445-41acae7a33fb",
    "name": "Currently Running on Google",
    "flow": "LOOKUP_2_SMS",
    "approach": "DEFAULT",
    "prep_step": false,
    "supported_languages": {
        "hr": "Croatian",
    },
    "country_code": "me",
    "country_name": "Montenegro",
    "logo_url": "https://d388jo7ckl8y3b.cloudfront.net/images/braingym.png",
    "logo_position": "LEFT",
    "logo_size": "Small",
    "logo_under_button": false,
    "texts": {
        "hr": [
            {
                "title": "Vaš sadržaj je spreman za preuzimanje",
                "sec_title": "",
                "description": "<p>Unesite broj svog mobilnog telefona da pristupite vašem preuzimanju</p>",
                "paragraph_under_button": "",
                "button_text": "Nastavi",
                "button_caption": ""
            },
            {
                "title": "Vaše preuzimanje je spremno",
                "sec_title": "",
                "description": "<p>Pošaljite SMS na kratki kod za pristup\n</p>",
                "paragraph_under_button": "",
                "button_text": "POŠALJI SMS",
                "button_caption": ""
            }
        ]
    },
    "titles_color": "#000000",
    "text_color": "black",
    "font_family": "",
    "button_text_color": "white",
    "button_primary_color": "#5cb85c",
    "background_color": "#FFF",
    "header_background_color": "#E2F1FF",
    "background_image": null,
    "text_above_player": "",
    // "featured_image": "https://d388jo7ckl8y3b.cloudfront.net/images/download.png",
    "featured_image": "https://nj-cdn.s3.us-west-2.amazonaws.com/images/mobile-check.gif",
    "bottom_text": "<p><span style=\"font-size: 8pt;\">Unosom broja i slanjem potvrde DA na kratki broj 14641 (Crnogorski Telekom), odnosno slanjem ključne riječi GYM na 14641 (M:tel i One), potvrđujete da se pročitali uslove korištenja i da se slažete s istim. Ovo je pretplatnička usluga. Usluga košta 2,40 EUR sedmično za sve korisnike (0,80 EUR/SMS, 3 SMS-a sedmično) + standardni troškovi operatera. U cijenu usluge je uključen PDV. Molimo Vas tražite odobrenje roditelja ili staratelja ukoliko imate ispod 18 godina. Pretplativši se na uslugu, korisnik dobija pristup vrhunskim sadržajima za trening mozga. Pretplata se obnavlja automatski sve dok ne pošaljete poruku sadržaja STOP GYM na 14641. Vaš mobilni telefon mora imati mogućnost pristupa Internetu, primanja i slanja tekstualnih poruka te primanja sadržaja. Korisnička podrška: +38220405889, braingym.me@silverlines.info. Radno vrijeme korisničke podrške 09:00 – 17:00. Davalac usluge: Qzink B.V., Weena Zuid 130, 3012 NC Rotterdam, Holandija.</span></p>",
    "terms_text": "Uslovi korišćenja",
    "terms_url": "http://brain-gym.mobi/me/terms-and-conditions",
    "policy_text": "Politika privatnosti",
    "policy_url": "http://brain-gym.mobi/me/privacy",
    "terms_agreement_text": "",
    "sticky_top_right_text": "",
    "terms_checkbox_switch": false,
    "terms_autocheck_with_click": false,
    "default_terms_switch_status": false,
    "append_terms_link_to_consent": false,
    "show_carriers": false,
    "show_rating": true,
    "show_country_flag": true,
    "layout": "FULL_WIDTH",
    "boxed_background_color": "#ffffff",
    "carriers": null,
    "validate_msisdn": true
}
