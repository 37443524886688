let me;
export default me = {
    "public_id": "e80cb7ee-2693-4783-9f34-aabfbf3b3bc5",
    "template": "generic-download-2",
    "campaign": "07331288-6ab9-450e-b41c-4d0c1ddef04e",
    "name": "Currently Running on Google",
    "flow": "MSISDN_REDIRECT",
    "approach": "DEFAULT",
    "prep_step": false,
    "supported_languages": {
        "hr": "Croatian",
    },
    "country_code": "lu",
    "country_name": "Luxembourg",
    "logo_url": "https://mindacademy.life/mindacademy.mobi_files/logo.png",
    "logo_position": "LEFT",
    "logo_size": "Small",
    "logo_under_button": false,
    "texts": {
        "hr": [
            {
                "title": "Votre contenu est prêt",
                "sec_title": "",
                "description": "<p>Entrez votre numéro de téléphone</p>",
                "paragraph_under_button": "",
                "button_text": "Continuer",
                "button_caption": ""
            },
            {
                "title": "Votre téléchargement est prêt",
                "sec_title": "",
                "description": "<p>Envoyez le court code d'accès par SMS</p>",
                "paragraph_under_button": "",
                "button_text": "ENVOYER UN SMS",
                "button_caption": ""
            }
        ]
    },
    "titles_color": "#000000",
    "text_color": "black",
    "font_family": "",
    "button_text_color": "white",
    "button_primary_color": "#5cb85c",
    "background_color": "#FFF",
    "header_background_color": "#E2F1FF",
    "background_image": null,
    "text_above_player": "",
    "featured_image": "https://d388jo7ckl8y3b.cloudfront.net/images/download.png",
    "bottom_text": "<p><span style=\"font-size: 8pt;\">Ce service est présenté par Mobeetech B.V.. Mindacademy service d'abonnement, 3x3 euro par semaine (=9 euro au total), pour arrêter envoyez \"STOP MIND\" à 67870. Voici comment fonctionne Mindacademy : Le participant s'inscrit avec son numéro de téléphone portable et doit ensuite confirmer son inscription en envoyant un mot clé MIND au 67870. Ce faisant, l'abonné confirme avoir lu et compris les conditions générales et s'engage à souscrire au service. Mindacademy : Des milliers de jeux pour votre telephone. L'abonnement coûte 9€/semaine (3€ x 3 SMS/semaine). Tous les montants seront facturés sur le compte mobile de l'abonné ou débités du solde de sa carte prépayée. Le service est un abonnement à renouvellement automatique qui peut être résilié à tout moment par le participant en envoyant un SMS avec « STOP MIND » au 67870. Si le service n'est pas arrêté, il sera automatiquement renouvelé pour une semaine. Ce service est fourni par Mobeetech B.V.. +35227860622, mobeetech.lu@silverlines.info</span></p>",
    "terms_text": "Conditions Générales",
    "terms_url": "https://scmp.moboffers.mobi/lu/mindacademy/policies/conditions-generales?language=fr",
    "policy_text": "Contact",
    "policy_url": "https://scmp.moboffers.mobi/lu/mindacademy/policies/contact?language=fr",
    "terms_agreement_text": "En cochant cette case, je souscrit a l’abonnement Mindacademy",
    "sticky_top_right_text": "",
    "terms_checkbox_switch": true,
    "terms_autocheck_with_click": false,
    "default_terms_switch_status": true,
    "append_terms_link_to_consent": false,
    "show_carriers": false,
    "show_rating": true,
    "show_country_flag": true,
    "layout": "FULL_WIDTH",
    "boxed_background_color": "#ffffff",
    "carriers": null,
    "validate_msisdn": true
}
