let ba;
export default ba = {
    "public_id": "b69764a1-0109-4c89-97bf-fdeb02a251cb",
    "template": "generic-download-2",
    "campaign": "b3746142-0cc7-4471-b970-263c7783de1c",
    "name": "Currently Running on Google",
    "flow": "INIT_MSISDN_REDIRECT",
    "approach": "DEFAULT",
    "prep_step": false,
    "supported_languages": {
        "hr": "Croatian",
    },
    "country_code": "ba",
    "country_name": "Bosnia",
    "logo_url": "https://d388jo7ckl8y3b.cloudfront.net/images/hqbrainfitness.png",
    "logo_position": "LEFT",
    "logo_size": "Small",
    "logo_under_button": false,
    "texts": {
        "hr": [
            {
                "title": "Vaš sadržaj je spreman za preuzimanje",
                "sec_title": "",
                "description": "<p>Unesite broj svog mobilnog telefona da pristupite vašem preuzimanju</p>",
                "paragraph_under_button": "",
                "button_text": "Nastavi",
                "button_caption": ""
            },
            {
                "title": "Vaše preuzimanje je spremno",
                "sec_title": "",
                "description": "<p>Pošaljite SMS na kratki kod za pristup\n</p>",
                "paragraph_under_button": "",
                "button_text": "POŠALJI SMS",
                "button_caption": ""
            }
        ]
    },
    "titles_color": "#000000",
    "text_color": "black",
    "font_family": "",
    "button_text_color": "white",
    "button_primary_color": "#05d90e",
    "background_color": "#E2F1FF",
    "header_background_color": "#FFF",
    "background_image": null,
    "text_above_player": "",
    "featured_image": "https://scmp.globalads.mobi/me/hqbrainfitness/redirect/img/hero.png",
    "bottom_text": "<p><span style=\"font-size: 8pt;\">Unosom broja i slanjem potvrde DA na kratki broj 06202020 (BH Telecom), odnosno slanjem ključne riječi HQB na 0651388 (m:tel) ili na 063770100 (eronet), potvrđujete da se pročitali uslove korištenja i da se slažete s istim. Ovo je pretplatnička usluga. Usluga košta 4 KM sedmično, 4 KM/SMS, po tjednu SMS-a sedmično (BH Telecom, HT Eronet) ili 4 sedmično, 4 KM/SMS, po tjednu SMS-a sedmično (m:tel) + standardni troškovi operatera. U cijenu usluge je uključen PDV. Molimo Vas tražite odobrenje roditelja ili staratelja ukoliko imate ispod 18 godina. Pretplativši se na uslugu, korisnik dobija pristup vrhunskim sadržajima za trening mozga. Pretplata se obnavlja automatski sve dok ne pošaljete poruku sadržaja STOP HQB na 06202020 (BH Telecom), 063770100 (HT Eronet) ili 0651388 (m:tel). Vaš mobilni telefon mora imati mogućnost pristupa Internetu, primanja i slanja tekstualnih poruka te primanja sadržaja. Za pomoć, pošaljite tekst HELP na kratki broj 063770100 (HT Eronet). Dodatnu pomoć korisnici BH Telecoma mogu dobiti slanjem ključne riječi POMOC ili ? na kratki broj 06202020. Korisnička podrška: +38737307900, adstron.ba@silverlines.info. Radno vrijeme korisničke podrške 09:00 – 17:00. Davalac usluge: Adstron B.V, Hart van Brabantlaan 12, 5038 JL Tilburg, Holandija.</span></p>",
    "terms_text": "Terms",
    "terms_url": "https://ba.hqbraingamesonline.com/ba/terms.html",
    "policy_text": "Privacy",
    "policy_url": "https://ba.hqbraingamesonline.com/ba/privacy.html",
    "terms_agreement_text": "",
    "sticky_top_right_text": "",
    "terms_checkbox_switch": false,
    "terms_autocheck_with_click": false,
    "default_terms_switch_status": false,
    "append_terms_link_to_consent": false,
    "show_carriers": false,
    "show_rating": true,
    "show_country_flag": true,
    "layout": "FULL_WIDTH",
    "boxed_background_color": "#ffffff",
    "carriers": null,
    "validate_msisdn": true
}
