let rs;
export default rs = {
    "public_id": "490fd778-baf5-4069-9ddf-a7b863cd3ff5",
    "template": "generic-download-2",
    "campaign": "391aebdc-c78e-4e01-85d8-e20559e2a43c",
    "name": "Currently Running on Google",
    "flow": "PIN_FLOW",
    "approach": "DEFAULT",
    "prep_step": false,
    "supported_languages": {
        "rs": "Serbian",
    },
    "country_code": "rs",
    "country_name": "Serbia",
    "logo_url": "https://d388jo7ckl8y3b.cloudfront.net/images/rapidworkoutstv.png",
    "logo_position": "LEFT",
    "logo_size": "Small",
    "logo_under_button": false,
    "texts": {
        "rs": [
            {
                "title": "Ваш садржај је спреман за преузимање",
                "sec_title": "",
                "description": "<p>Унесите број свог мобилног телефона да бисте приступили преузимању</p>",
                "paragraph_under_button": "",
                "button_text": "Пошаљи код",
                "button_caption": "Usluga košta 360 RSD nedeljno"
            },
            {
                "title": "Датотека за преузимање је спремна",
                "sec_title": "",
                "description": "<p>Унесите примљени ПИН код</p>",
                "paragraph_under_button": "",
                "button_text": "Пошаљи код",
                "button_caption": "Usluga košta 360 RSD nedeljno"
            }
        ]
    },
    "titles_color": "#000000",
    "text_color": "black",
    "font_family": "",
    "button_text_color": "white",
    "button_primary_color": "#05d90e",
    "background_color": "#E2F1FF",
    "header_background_color": "#FFF",
    "background_image": null,
    "text_above_player": "",
    "featured_image": "https://d388jo7ckl8y3b.cloudfront.net/images/download.png",
    "bottom_text": "<p><span style=\"font-size: 8pt;\">Ostanite zdravi i fit uz dnevni set Rapid-Workout vježbi. Usluga košta 180din/sms, 2 sms/nedeljno za MTS ,Yettel i A1 korisnike (plus cena osnovnog SMS-a: MTS 3,60 DIN, Yettel 4,90 DIN, Vip 3,48 DIN, Globaltel 3,48 DIN) (Sve navedene cene sa uracunatim PDV-om). Za odjavu sa servisa posaljite STOP RAPID na 1556 za Yettel & A1 korisnike ili STOPRAPID na 1556 za Telekom korisnike. Cena odjave servisa je: Telekom 3,60 DIN, Yettel 4,90 DIN, A1 3,48 DIN, Globaltel 3,48 DIN. Registracijom na ovu uslugu potvrđujete da ste saglasni sa svim važećim odredbama i uslovimaAdstron B.V.. Svi testovi, igre i/ili aplikacije , video snimci i slike na ovoj stranici su u svrhu zabave. Kontakt podrška: adstron.rs@silverlines.info, +381113216815 (lokalna naplata). Ovu uslugu nudi vam Adstron BV, Hart van Brabantlaan 12, 5038 JL Tilburg, Nizozemska. Tehnički pružalac usluge NTH Media d.o.o. Beograd.</span></p>",
    "terms_text": "Opšti uslovi korišćenja",
    "terms_url": "https://rapid-workoutstv.com/rs/terms-and-conditions",
    "policy_text": "Pomoć",
    "policy_url": "https://rapid-workoutstv.com/rs/privacy",
    "terms_agreement_text": "",
    "sticky_top_right_text": "",
    "terms_checkbox_switch": false,
    "terms_autocheck_with_click": false,
    "default_terms_switch_status": false,
    "append_terms_link_to_consent": false,
    "show_carriers": false,
    "show_rating": true,
    "show_country_flag": true,
    "layout": "FULL_WIDTH",
    "boxed_background_color": "#ffffff",
    "carriers": null,
    "validate_msisdn": true
}
