import { isMobile } from 'react-device-detect';
import VideoBg from '../../assets/images/video_bg.png'
import VideoBgMobile from '../../assets/images/video_bg_mobile.png'

function VideoPlayer2({bgClass, bgImage}) {
    return (
        <div className={`video-bg ${bgClass}`} style={{position: 'relative',
            backgroundImage: `url(${bgImage})`, backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
        <svg width="563" height="30" viewBox="0 0 563 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={{position: 'absolute', bottom: '0', left: '0'}}>
            <rect width="563" height="29" fill="#3F3F3F"/>
            <path d="M513.654 12.1182L511.539 13.8105H509.846V16.3489H511.539L513.654 18.0412V12.1182Z" stroke="white" stroke-width="0.846155" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M517.068 12.0876C517.861 12.881 518.307 13.957 518.307 15.0788C518.307 16.2007 517.861 17.2766 517.068 18.07M515.575 13.5811C515.971 13.9778 516.194 14.5158 516.194 15.0767C516.194 15.6376 515.971 16.1756 515.575 16.5723" stroke="white" stroke-width="0.846155" stroke-linecap="round" stroke-linejoin="round"/>
            <g clip-path="url(#clip0_39_173)">
            <path d="M529.308 18.8857H528.038C527.814 18.8857 527.599 18.7966 527.44 18.6379C527.281 18.4792 527.192 18.264 527.192 18.0396V16.7704M534.808 16.7704V18.0396C534.808 18.264 534.719 18.4792 534.56 18.6379C534.401 18.7966 534.186 18.8857 533.961 18.8857H532.692M532.692 11.2704H533.961C534.186 11.2704 534.401 11.3595 534.56 11.5182C534.719 11.6769 534.808 11.8921 534.808 12.1165V13.3857M527.192 13.3857V12.1165C527.192 11.8921 527.281 11.6769 527.44 11.5182C527.599 11.3595 527.814 11.2704 528.038 11.2704H529.308" stroke="white" stroke-width="0.846155" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <path d="M550.885 15.5026C551.118 15.5026 551.308 15.3132 551.308 15.0796C551.308 14.8459 551.118 14.6565 550.885 14.6565C550.651 14.6565 550.462 14.8459 550.462 15.0796C550.462 15.3132 550.651 15.5026 550.885 15.5026Z" stroke="white" stroke-width="0.846155" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M547.923 15.5026C548.157 15.5026 548.346 15.3132 548.346 15.0796C548.346 14.8459 548.157 14.6565 547.923 14.6565C547.689 14.6565 547.5 14.8459 547.5 15.0796C547.5 15.3132 547.689 15.5026 547.923 15.5026Z" stroke="white" stroke-width="0.846155" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M544.962 15.5026C545.195 15.5026 545.385 15.3132 545.385 15.0796C545.385 14.8459 545.195 14.6565 544.962 14.6565C544.728 14.6565 544.539 14.8459 544.539 15.0796C544.539 15.3132 544.728 15.5026 544.962 15.5026Z" stroke="white" stroke-width="0.846155" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 15C14 14.4477 14.4477 14 15 14H498C498.552 14 499 14.4477 499 15V15C499 15.5523 498.552 16 498 16H15C14.4477 16 14 15.5523 14 15V15Z" fill="#D9D9D9"/>
            <path d="M14 15C14 14.4477 14.4477 14 15 14H229C229.552 14 230 14.4477 230 15V15C230 15.5523 229.552 16 229 16H15C14.4477 16 14 15.5523 14 15V15Z" fill="#7A27E4"/>
            <circle cx="13.74" cy="14.9997" r="3.73996" fill="white"/>
            <defs>
            <clipPath id="clip0_39_173">
            <rect width="10.1539" height="10.1539" fill="white" transform="matrix(1 0 0 -1 525.923 20.1541)"/>
            </clipPath>
            </defs>
        </svg>
        </div>

    );
}

export default VideoPlayer2;