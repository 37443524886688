let conf;
export default conf = {
    "public_id": "03dcd639-b590-4d8c-9715-1890c75872c1",
    "template": "default",
    "campaign": "7b8de859-62f4-4939-b08f-e59d133cf5b4",
    "name": "Currently Running on Google",
    "flow": "CLICK_2_SMS",
    "approach": "DEFAULT",
    "prep_step": false,
    "supported_languages": {
        "fr": "French",
    },
    "country_code": "fr",
    "country_name": "France",
    "logo_url": "https://nj-cdn.s3.us-west-2.amazonaws.com/images/etheria-logo-wide.png",
    "logo_position": "LEFT",
    "logo_size": "Small",
    "logo_under_button": false,
    "texts": {
        "fr": [
            {
                "title": "Activer en envoyant COMMENCER au 84071",
                "sec_title": "sec title",
                "description": "L'utilisateur bénéficie d'un accès illimité à plus de 15 jeux et tournois pendant 30 jours ",
                "paragraph_under_button": '<p><img style="width: 50px; height: auto; margin-right:5px;" src="/static/media/smsplus.png"></img>Etheria Games est votre seul endroit pour profiter des jeux sociaux, de l’esport et rivaliser avec vos amis en jouant à vos jeux préférés. Après s’être abonné à Etheria Games, l’utilisateur a la possibilité de jouer à tous les jeux en ligne de manière illimitée pendant 30 jours. Le prix du service est de 4.50 €. Il s’agit de frais uniques. L’Activation du service : Veuillez envoyer un SMS avec le texte COMMENCER au 84071. En activant le service, vous confirmez que vous avez lu le Règlement et que vous acceptez de recevoir gratuitement des informations de commercialisation et de publicité du fournisseur de service. Les frais ne comprennent pas l’utilisation de l’Internet mobile. Aide : +33170700354 du lundi au vendredi de 9h00 à 17h00 ou envoyez un e-mail à noju.fr@silverlines.info. Fournisseur de services Noju LLC, 16192 Coastal Highway, Delaware United States of America.</p>',
                "button_text": "Confirmer",
                "button_caption": "4.50 EUROS par SMS + prix SMS."
            },
            {
                "title": "Activer en envoyant COMMENCER au 84071",
                "sec_title": "sec_title",
                "description": "L'utilisateur bénéficie d'un accès illimité à plus de 15 jeux et tournois pendant 30 jours",
                "paragraph_under_button": '<p><img style="width: 50px; height: auto; margin-right:5px;" src="/static/media/smsplus.png"></img>Etheria Games est votre seul endroit pour profiter des jeux sociaux, de l’esport et rivaliser avec vos amis en jouant à vos jeux préférés. Après s’être abonné à Etheria Games, l’utilisateur a la possibilité de jouer à tous les jeux en ligne de manière illimitée pendant 30 jours. Le prix du service est de 4.50 €. Il s’agit de frais uniques. L’Activation du service : Veuillez envoyer un SMS avec le texte COMMENCER au 84071. En activant le service, vous confirmez que vous avez lu le Règlement et que vous acceptez de recevoir gratuitement des informations de commercialisation et de publicité du fournisseur de service. Les frais ne comprennent pas l’utilisation de l’Internet mobile. Aide : +33170700354 du lundi au vendredi de 9h00 à 17h00 ou envoyez un e-mail à noju.fr@silverlines.info. Fournisseur de services Noju LLC, 16192 Coastal Highway, Delaware United States of America.</p>',
                "button_text": "Confirmer",
                "button_caption": "4.50 EUROS par SMS + prix SMS."
            },
        ],
    },
    "titles_color": "#000000",
    "text_color": "black",
    "font_family": "",
    "button_text_color": "white",
    "button_primary_color": "#3E5FFA",
    "background_color": "#E2F1FF",
    "header_background_color": "#FFF",
    "background_image": null,
    "player_image": "https://d202f37y8mzl74.cloudfront.net/dh-games/page_configurations/2023/9/26/page_config_fi_-494.png",
    "text_above_player": "",
    "featured_image": "https://d202f37y8mzl74.cloudfront.net/dh-games/page_configurations/2023/9/26/page_config_fi_-494.png",
    "bottom_text": "",
    "terms_text": "Terms & Conditions",
    "terms_url": "https://nj-static-pages.s3.us-west-2.amazonaws.com/etherea-games/france/index.html",
    "policy_text": "",
    "policy_url": "",
    "terms_agreement_text": "",
    "sticky_top_right_text": "",
    "terms_checkbox_switch": false,
    "terms_autocheck_with_click": false,
    "default_terms_switch_status": false,
    "append_terms_link_to_consent": false,
    "keyword": "COMMENCER",
    "show_carriers": false,
    "show_rating": true,
    "show_country_flag": true,
    "layout": "FULL_WIDTH",
    "boxed_background_color": "#ffffff",
    "carriers": null,
    "validate_msisdn": true,
}