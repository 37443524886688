import Logo from "../../components/Logo";
import Flow from "../../components/Flow";
import VideoPlayer from "../../components/VideoPlayer";
import {useState, useEffect, useRef} from "react";
import {useSystem} from "../../hooks/use-system";
import LanguageSelector from "../../components/LanguageSelector";


function DownloadGeneric() {


    const {config, language} = useSystem();
    const [bottomParagraph, setBottomParagraph] = useState("");

    return (
        <div id="main" style={{
            backgroundImage: `url(${config.background_image})`, //setting the background image for the page
            backgroundColor: config.background_color, //if no background image then use background color
            color: config.text_color,
            fontFamily: config.font_family,
        }}>
            <div id={"mainBox"} className={config.layout === 'BOXED' ? 'active' : ''} style={{
                backgroundColor: config.layout === 'BOXED' ? config.boxed_background_color : ''
            }}>
                <header>
                    {config.logo_url && !config.logo_under_button &&
                        <Logo logo_url={config.logo_url} logo_position={config.logo_position}
                              logo_size={config.logo_size}/>
                    }
                </header>

                {config.sticky_top_right_text && <p className={'text-center me-1 pe-1 my-1'} style={{
                    lineHeight: '100%',
                    display: 'flex',
                    justifyContent: 'end',
                    fontSize: '.8rem'
                }} dangerouslySetInnerHTML={{__html: config.sticky_top_right_text}}></p>}
                
                <LanguageSelector supportedLanguages={config.supported_languages}/>

                {config.approach === 'DEFAULT' && config.featured_image && (
                    <section className={'mx-0 featured-image text-center d-block'}>
                        <img src={config.featured_image} className={'img-fluid'}/>
                    </section>
                )}

                <section className={'my-auto'}>
                    {
                        // config.approach.includes('VIDEO')
                        //     ?
                        //     <div
                        //         className={`video-player mx-auto ${config.approach === 'VIDEO' ? 'video-flow-container' : ''}`}
                        //         style={{width: '100%'}}
                        //         ref={PlayerContainerRef}
                        //         onClick={
                        //             () => config.approach === 'VIDEO_POPUP' ? system.showModal('flowModal', {
                        //                 config,
                        //                 setBottomParagraph
                        //             }) : null
                        //         }
                        //     />
                        //      : 
                            <Flow config={config} language={language} setBottomParagraph={setBottomParagraph}/>
                    }
                </section>
                {config.logo_url && config.logo_under_button &&
                    <Logo logo_url={config.logo_url} logo_position={config.logo_position}
                          logo_size={config.logo_size} classes={'bottom'}/>
                }
                <footer className={'mt-auto mb-1'}>
                    <div className="container">
                        <div className={'row'}>
                            <div className={'col'}>
                                <div className="paragraph smaller"
                                     dangerouslySetInnerHTML={{__html: bottomParagraph}}>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {config.flow_icon &&
                                <div className={"col-12 my-1"}>
                                    <img src={config.flow_icon}/>
                                </div>
                            }
                            <div className="col-12 text-center">
                                <a href={config.terms_url}>{config.terms_text}</a>
                                {
                                    config.policy_url &&
                                    <>
                                        &nbsp; <span className="text-light">|</span> &nbsp;
                                        <a
                                            style={{color: config.titles_color}}
                                            href={config.policy_url}
                                            rel="noreferrer"
                                            target={'_blank'}>{config.policy_text}</a>
                                    </>
                                }
                                {
                                    config.about_text &&
                                    <>
                                        &nbsp; <span className="text-light">|</span> &nbsp;
                                        <a
                                            style={{color: config.titles_color}}
                                            href={config.about_url}
                                            rel="noreferrer"
                                            target={'_blank'}>{config.about_text}</a>
                                    </>
                                }
                                {
                                    config.disclaimer_text &&
                                    <>
                                        &nbsp; <span className="text-light">|</span> &nbsp;
                                        <a
                                            style={{color: config.titles_color}}
                                            href={config.disclaimer_url}
                                            rel="noreferrer"
                                            target={'_blank'}>{config.disclaimer_text}</a>
                                    </>
                                }
                                {
                                    config.contact_us_text &&
                                    <>
                                        &nbsp; <span className="text-light">|</span> &nbsp;
                                        <a
                                            style={{color: config.titles_color}}
                                            href={config.contact_us_url}
                                            rel="noreferrer"
                                            target={'_blank'}>{config.contact_us_text}</a>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            {config.bottom_text &&
                <div id={'bottomText'} dangerouslySetInnerHTML={{__html: config.bottom_text}}></div>
            }

        </div>
    )

}

export default DownloadGeneric;