let me;
export default me = {
    "public_id": "57dde9ae-60d9-4914-b62b-56525874a7ca",
    "template": "generic-download-2",
    "campaign": "891b57cf-61f6-4f76-bea2-9ba781780d7a",
    "name": "Currently Running on Google",
    "flow": "MSISDN_REDIRECT",
    "approach": "DEFAULT",
    "prep_step": false,
    "supported_languages": {
        "de": "Deutsch",
        "en": "English"
    },
    "country_code": "ch",
    "country_name": "Switzerland",
    "logo_url": "https://nj-cdn.s3.us-west-2.amazonaws.com/images/mobileplay.png",
    "logo_position": "LEFT",
    "logo_size": "Small",
    "logo_under_button": false,
    "texts": {
        "de": [
            {
                "title": "Ihr Inhalt steht zum Download bereit",
                "sec_title": "",
                "description": "<p>Geben Sie Ihre Mobiltelefonnummer ein, um auf Ihren Download zuzugreifen</p>",
                "paragraph_under_button": "",
                "button_text": "Nastavi",
                "button_caption": ""
            },
            {
                "title": "Ihr Download ist fertigo",
                "sec_title": "",
                "description": "<p>Senden Sie eine SMS an den kurzen Zugangscode</p>",
                "paragraph_under_button": "",
                "button_text": "POŠALJI SMS",
                "button_caption": ""
            }
        ],
        "en": [
            {
                "title": "Your content is ready for download",
                "sec_title": "",
                "description": "<p>Enter your mobile number to access your download</p>",
                "paragraph_under_button": "",
                "button_text": "Submit",
                "button_caption": ""
            },
            {
                "title": "Your download is complete",
                "sec_title": "",
                "description": "<p>Send an SMS to the short code</p>",
                "paragraph_under_button": "",
                "button_text": "Send SMS",
                "button_caption": ""
            }
        ]
    },
    "titles_color": "#000000",
    "text_color": "#000000",
    "font_family": "",
    "button_text_color": "white",
    "button_primary_color": "#05d90e",
    "background_color": "#FFF",
    "header_background_color": "#00152F",
    "header_font_color": "#FFF",
    "background_image": null,
    "text_above_player": "",
    "featured_image": "https://nj-cdn.s3.us-west-2.amazonaws.com/images/mobile_download.gif",
    "bottom_text": "<p><span style=\"font-size: 8pt;\">Mobile-Play gibt Ihnen rund um die Uhr unbegrenzten Zugang zu exklusiven Videos, Wallpapers, Musik & Spielen. Geben Sie Ihre Nummer ein und antworten Sie mit JA (für Salt und Sunrise) oder START ABO (für Swisscom). Um sich zu registrieren oder den Dienst zu nutzen, müssen Sie Abonnent/Kontoinhaber sein und mindestens 18 Jahre alt sein oder die ausdrückliche Erlaubnis des Abonnenten/Kontoinhabers haben. Wenn Sie sich abmelden möchten, senden Sie eine SMS mit dem Stichwort: STOP PLAY an 554 (für Salt und Sunrise) oder STOP PLAY an 556 (für Swisscom) oder kontaktieren Sie uns über unsere Direktwahl: +41445816285 oder per E- Mail mountmedia.ch@silverlines.info Dienst wird von MountMediaSolutions B.V., Trasmolenlaan 12, 3447 GZ Woerden, Die Niederlande, bereitgestellt. Das Abo kostet CHF 10 pro Woche (1 SMS / Woche für Sunrise, 2 SMS / Woche für Salt und Swisscom).</span></p>",
    "terms_text": "AGB",
    "terms_url": "https://signups-ch.mobilespacegames.com/ch/agb.html",
    "policy_text": "Kundenservice",
    "policy_url": "https://signups-ch.mobilespacegames.com/ch/Kundenservice.html",
    "terms_agreement_text": "",
    "sticky_top_right_text": "",
    "terms_checkbox_switch": false,
    "terms_autocheck_with_click": false,
    "default_terms_switch_status": false,
    "append_terms_link_to_consent": false,
    "show_carriers": false,
    "show_rating": true,
    "show_country_flag": true,
    "layout": "FULL_WIDTH",
    "boxed_background_color": "#ffffff",
    "carriers": null,
    "validate_msisdn": true
}
