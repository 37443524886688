let me;
export default me = {
    "public_id": "addf4a62-6615-4d49-9a2e-06f6e2aaef7b",
    "template": "generic-download-2",
    "campaign": "ffdf5ae3-02d5-4c0c-976d-db64d08a4422",
    "name": "Currently Running on Google",
    "flow": "MSISDN_REDIRECT",
    "approach": "DEFAULT",
    "prep_step": false,
    "supported_languages": {
        "de": "Deutsch",
        "en": "English"
    },
    "country_code": "ch",
    "country_name": "Switzerland",
    "logo_url": "https://mindacademy.life/mindacademy.mobi_files/logo.png",
    "logo_position": "LEFT",
    "logo_size": "Small",
    "logo_under_button": false,
    "texts": {
        "de": [
            {
                "title": "Ihr Inhalt steht zum Download bereit",
                "sec_title": "",
                "description": "<p>Geben Sie Ihre Mobiltelefonnummer ein, um auf Ihren Download zuzugreifen</p>",
                "paragraph_under_button": "",
                "button_text": "Nastavi",
                "button_caption": ""
            },
            {
                "title": "Ihr Download ist fertigo",
                "sec_title": "",
                "description": "<p>Senden Sie eine SMS an den kurzen Zugangscode</p>",
                "paragraph_under_button": "",
                "button_text": "POŠALJI SMS",
                "button_caption": ""
            }
        ],
        "en": [
            {
                "title": "Your content is ready for download",
                "sec_title": "",
                "description": "<p>Enter your mobile number to access your download</p>",
                "paragraph_under_button": "",
                "button_text": "Submit",
                "button_caption": ""
            },
            {
                "title": "Your download is complete",
                "sec_title": "",
                "description": "<p>Send an SMS to the short code</p>",
                "paragraph_under_button": "",
                "button_text": "Send SMS",
                "button_caption": ""
            }
        ]
    },
    "titles_color": "#000000",
    "text_color": "black",
    "font_family": "",
    "button_text_color": "white",
    "button_primary_color": "#5cb85c",
    "background_color": "#FFF",
    "header_background_color": "#E2F1FF",
    "background_image": null,
    "text_above_player": "",
    "featured_image": "https://d388jo7ckl8y3b.cloudfront.net/images/download.png",
    "bottom_text": "<p><span style=\"font-size: 8pt;\">Ce service est présenté par Mobeetech B.V.. Mindacademy service d'abonnement, 3x3 euro par semaine (=9 euro au total), pour arrêter envoyez \"STOP MIND\" à 67870. Voici comment fonctionne Mindacademy : Le participant s'inscrit avec son numéro de téléphone portable et doit ensuite confirmer son inscription en envoyant un mot clé MIND au 67870. Ce faisant, l'abonné confirme avoir lu et compris les conditions générales et s'engage à souscrire au service. Mindacademy : Des milliers de jeux pour votre telephone. L'abonnement coûte 9€/semaine (3€ x 3 SMS/semaine). Tous les montants seront facturés sur le compte mobile de l'abonné ou débités du solde de sa carte prépayée. Le service est un abonnement à renouvellement automatique qui peut être résilié à tout moment par le participant en envoyant un SMS avec « STOP MIND » au 67870. Si le service n'est pas arrêté, il sera automatiquement renouvelé pour une semaine. Ce service est fourni par Mobeetech B.V.. +35227860622, mobeetech.lu@silverlines.info</span></p>",
    "terms_text": "Conditions Générales",
    "terms_url": "https://scmp.moboffers.mobi/lu/mindacademy/policies/conditions-generales?language=fr",
    "policy_text": "Contact",
    "policy_url": "https://scmp.moboffers.mobi/lu/mindacademy/policies/contact?language=fr",
    "terms_agreement_text": "En cochant cette case, je souscrit a l’abonnement Mindacademy",
    "sticky_top_right_text": "",
    "terms_checkbox_switch": false,
    "terms_autocheck_with_click": false,
    "default_terms_switch_status": true,
    "append_terms_link_to_consent": false,
    "show_carriers": false,
    "show_rating": true,
    "show_country_flag": true,
    "layout": "FULL_WIDTH",
    "boxed_background_color": "#ffffff",
    "carriers": null,
    "validate_msisdn": true
}
