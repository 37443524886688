let me;
export default me = {
    "public_id": "8207792e-217d-4c63-9a0e-ab3001c8a065",
    "template": "generic-download-2",
    "campaign": "f99e981a-af78-4671-9a3d-0dbcea943aae",
    "name": "Currently Running on Google",
    "flow": "INIT_MSISDN_REDIRECT",
    "approach": "DEFAULT",
    "prep_step": false,
    "supported_languages": {
        "hr": "Croatian",
    },
    "country_code": "me",
    "country_name": "Montenegro",
    "logo_url": "https://nj-cdn.s3.us-west-2.amazonaws.com/images/etheria-logo-wide.png",
    "logo_position": "LEFT",
    "logo_size": "Small",
    "logo_under_button": false,
    "texts": {
        "hr": [
            {
                "title": "Vaš sadržaj je spreman za preuzimanje",
                "sec_title": "",
                "description": "<p>Unesite broj svog mobilnog telefona da pristupite vašem preuzimanju</p>",
                "paragraph_under_button": "",
                "button_text": "Nastavi",
                "button_caption": ""
            },
            {
                "title": "Vaše preuzimanje je spremno",
                "sec_title": "",
                "description": "<p>Pošaljite SMS na kratki kod za pristup\n</p>",
                "paragraph_under_button": "",
                "button_text": "POŠALJI SMS",
                "button_caption": ""
            }
        ]
    },
    "titles_color": "#000000",
    "text_color": "black",
    "font_family": "",
    "button_text_color": "white",
    "button_primary_color": "#05d90e",
    "background_color": "#FFF",
    "header_background_color": "#E2F1FF",
    "background_image": null,
    "text_above_player": "",
    "featured_image": "https://nj-cdn.s3.us-west-2.amazonaws.com/images/mobile_download.gif",
    "bottom_text": "<p><span style=\"font-size: 8pt;\">Registracijom na uslugu potvrđujete da se pročitali Uslove Korištenja i da se slažete sa istim. Ovo je pretplatnička usluga. Usluga košta 6,00 KM sedmično, 3,00 KM/SMS, 2 SMS-a sedmično (BH Telecom, HT Eronet) ili 4,00 KM sedmično, 2,00 KM/SMS, 2 SMS-a sedmično (m:tel) + standardni troškovi operatera. U cijenu usluge je uključen PDV. Etheria igre je vaše jedno mjesto za uživanje u društvenim igrama, esportu i natjecanju sa svojim prijateljima koji igraju vaše omiljene igre. Kako biste pristupili unesite broj i pošaljite potvrdu DA na kratki broj 06202020 (BH Telecom), odnosno pošaljite ključnu riječ POCNI na 063770100 (Eronet) ili 0651388 (m:tel). Molimo Vas tražite odobrenje roditelja ili staratelja ukoliko imate ispod 18 godina. Pretplata se obnavlja automatski sve dok ne pošaljete poruku sadržaja STOP KEYWORD na 06202020 (BH Telecom), 063770100 (HT Eronet) ili 0651388 (m:tel). Vaš mobilni telefon mora imati mogućnost pristupa Internetu, primanja i slanja tekstualnih poruka te primanja sadržaja. Za pomoć, pošaljite tekst HELP na kratki broj 063770100 (HT Eronet). Dodatnu pomoć korisnici BH Telecoma mogu dobiti slanjem ključne riječi POMOC ili ? na kratki broj 06202020. Korisnička podrška: +38737307900, noju.ba@silverlines.info. Radno vrijeme korisničke podrške (09:00 – 17:00). Davalac usluge: Noju LLC, 16192 Coastal Highway, Delaware United States of America.</span></p>",
    "terms_text": "Uvjeti i odredbe ",
    "terms_url": "https://pages.etheria.games/montenegro/index.html",
    "policy_text": "Kontakt",
    "policy_url": "https://pages.etheria.games/montenegro/index.html",
    "terms_agreement_text": "",
    "sticky_top_right_text": "",
    "terms_checkbox_switch": false,
    "terms_autocheck_with_click": false,
    "default_terms_switch_status": false,
    "append_terms_link_to_consent": false,
    "show_carriers": false,
    "show_rating": true,
    "show_country_flag": true,
    "layout": "FULL_WIDTH",
    "boxed_background_color": "#ffffff",
    "carriers": null,
    "validate_msisdn": true
}
