import React from "react";
import Message from "../Message";
import {useSystem} from "../../hooks/use-system";
import AutoSMS from "./AutoSMS";
import FlowModal from "./FlowModal";

const SystemModals = () => {
    const {modal, processStages, message, messageType} = useSystem();

    const formatter = new Intl.NumberFormat('us-US', {maximumSignificantDigits: 8});

    if (message) {
        return <Message type={messageType}>{message}</Message>;
    }

    if (modal.name == 'autoSMS') {
        return <AutoSMS classNames={'text-center'} texts={modal.data.texts} short_code={modal.data.short_code}
                        keyword={modal.data.keyword}
                        duration={modal.data.duration}/>
    }
    if (modal.name == 'flowModal') {
        return <FlowModal config={modal.data.config} setBottomParagraph={modal.data.setBottomParagraph} />
    }

    return null;
};

export default SystemModals;
