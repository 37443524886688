import { Dropdown } from "react-bootstrap";
import { useSystem } from "../../hooks/use-system";
import { Globe } from "react-feather";

function LanguageSelector({supportedLanguages}) {
    const system = useSystem();
    const handleLangSelect = (lang)=>{
        localStorage.setItem('nj_lang', lang);
        system.setLanguage(lang);
    }
    return (
        Object.keys(supportedLanguages).length > 1 ?
        <div className="language-selector d-flex justify-content-center align-items-center">
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {system.language.toUpperCase()} <Globe size={18}/>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {Object.keys(supportedLanguages).map((lang)=>{
                        return  <Dropdown.Item onClick={()=>handleLangSelect(lang)} key={`lang_${lang}`}>
                                {lang.toUpperCase()}
                            </Dropdown.Item>
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
        : null
    );
}

export default LanguageSelector;
