import "./style.scss";
import OtpField from 'react-otp-field';
import {isAndroid, osVersion} from "react-device-detect";
import {InputGroup} from "react-bootstrap";
import lock from "../../assets/images/lock.svg"

function OTPField({color, ticketNumber, setTicketNumber, disabled}) {

    return (
        // true || isAndroid ? (
        <InputGroup className={'text-center d-flex align-items-center justify-content-center'}>
            <InputGroup.Text id="inputGroup-sizing-sm" style={{height: 40, backgroundColor: color}}>
                <img src={lock} className={'img-responsive'} width={20}/>
            </InputGroup.Text>
            <input placeholder={''} autoFocus={true} className={'otp-simple-field'} type={"text"} maxLength={8}
                   value={ticketNumber}
                   onChange={(e) => {
                       let v = e.target.value;
                       setTicketNumber(v)
                       // v = v.replace(/[^0-9]/g, '');
                       // if (v.length <= 4) {
                       //     setTicketNumber(v)
                       // } else {
                       //     setTicketNumber(v.slice(0, 4))
                       // }
                   }}/>
        </InputGroup>
        // ) : <OtpField
        //     value={ticketNumber}
        //     onChange={setTicketNumber}
        //     numInputs={4}
        //     onChangeRegex={/^([0-9]{0,})$/}
        //     autoFocus
        //     separator={<span></span>}
        //     isTypeNumber
        //     inputProps={{
        //         className: 'otp-field__input',
        //         disabled: disabled,
        //         pattern: "[0-9]*",
        //         inputMode: "numeric",
        //         min: 0
        //     }}
        // />
    );
};

export default OTPField